<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <template v-if="navShow">
    <NavBar v-show="navShow" :title="title" :isleftarrow="isleftarrow"></NavBar>
  </template>
  <router-view v-slot="{ Component }" >
    <keep-alive :key="$route.path">
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
    <div v-if="tabbarShow">
      <Bottomtabbar></Bottomtabbar>
    </div>
  </router-view>
</template>
<script>
// import { socketFun } from '@/utils/socket'
// import store from '@/store'
import NavBar from './common/header.vue'
import Bottomtabbar from './views/mainpage/bottomtabbar.vue'
export default {
  components: {
    NavBar,
    Bottomtabbar
  },
  data () {
    return {
      title: '',
      isleftarrow: '',
      transitionName: 'fade',
      navShow: true,
      tabbarShow: false,
      socket: undefined
    }
  },
  mounted () {
    // WebSockets.initWebSocket()
    // this.sendConnect()
    this.title = this.$route.meta.title
    this.isleftarrow = this.$route.meta.isleftarrow
    this.navShow = this.$route.meta.navShow
    this.tabbarShow = this.$route.meta.tabbarShow
    // if (this.$store.getters.getSocket) {
    //   this.socket = this.$store.getters.getSocket
    //   console.log('APP========= this.$store.getters.getSocket')
    // } else {
    //   const socket = socketFun()
    //   this.socket = socket
    //   console.log('APP========= this.socket')
    // }
    // this.socket.onmessage = function (e) {
    //   console.log('APP========= this.socket.onmessage')
    //   const data = JSON.parse(e.data)
    //   console.log('APP========= this.socket.onmessage' + data)
    //   store.commit('setMessages', data)
    // }
  },
  methods: {
    // sendConnect () {
    //   const myUserId = localStorage.getItem('userId')
    //   var _this = this
    //   var chatMsg = {}
    //   chatMsg.type = 1
    //   chatMsg.senderId = myUserId
    //   WebSockets.sendData(chatMsg, (msg) => {
    //     if (msg != null) {
    //       const res = JSON.parse(msg.data)
    //       console.log('聊天消息', res)
    //       console.log('msg', res)
    //       if (res.code === 200) {
    //         _this.reload = false
    //         _this.sendHearBeat()
    //       } else if (res.code === 400) {
    //         // this.closeSocket()
    //       } else {
    //         store.commit('setMessages', res)
    //       }
    //     }
    //   })
    // },
    // sendHearBeat () {
    //   var _this = this
    //   const myUserId = localStorage.getItem('userId')
    //   _this.timer = setInterval(function () {
    //     var chatMsg = {}
    //     chatMsg.type = 4
    //     chatMsg.senderId = myUserId
    //     WebSockets.sendDataWithoutCallback(chatMsg)
    //     console.log('发送心跳中')
    //   }, 10000)
    // },
    // closeSocket () {
    //   clearInterval(this.timer)
    //   WebSockets.closewebsocket()
    // }
  },
  watch: {
    $route (to, from) {
      this.title = to.meta.title
      this.isleftarrow = to.meta.isleftarrow
      this.navShow = to.meta.navShow
      this.tabbarShow = to.meta.tabbarShow
    }
    // getClosed () {
    //   this.closed = this.$store.state.closed
    //   console.log('closed===', this.closed)
    //   if (this.closed === true) {
    //     clearInterval(this.timer)
    //   } else {
    //     WebSockets.initWebSocket()
    //     this.sendConnect()
    //   }
    // }
  }
  // computed: {
  //   getClosed () {
  //     return this.$store.state.closed
  //   }
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* min-height: 100%; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
