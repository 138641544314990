'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
// var target = 'http://localhost:8080/'
//var target = 'http://192.168.120.156:8088/'
// 正式环境
var target = 'https://api.shuahuo.cn/'
// 正式环境
 var socketUrl = 'wss://api.shuahuo.cn/wss'
//var socketUrl = 'ws://192.168.120.156:8686/ws'
module.exports = {
  domain: domain,
  target: target,
  socketUrl: socketUrl
}
