<template>
  <div class="about">
    <h1>This is header</h1>
    <div>{{message}}</div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: [
    'message'
  ]
}
</script>
