import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/login/index.vue'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { title: '', isleftarrow: false, navShow: true },
    component: Login
  },
  {
    path: '/protocol',
    name: 'Protocol',
    meta: { title: '用户协议', isleftarrow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/login/protocol.vue')
  },
  {
    path: '/choose',
    name: 'Choose',
    meta: { title: '选择身份', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/login/choose.vue')
  },
  {
    path: '/baseInfo',
    name: 'BaseInfo',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/login/baseinfo.vue')
  },
  {
    path: '/identity',
    name: 'Identity',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/baseinfo/identity.vue')
  },
  {
    path: '/experience',
    name: 'Experience',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/baseinfo/experience.vue')
  },
  {
    path: '/recentlyJob',
    name: 'RecentlyJob',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/baseinfo/recentlyJob.vue')
  },
  {
    path: '/jobintention',
    name: 'Jobintention',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/baseinfo/jobintention.vue')
  },
  {
    path: '/baseInfoPay',
    name: 'BaseInfoPay',
    // meta: { title: '填写信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/baseinfo/pay.vue')
  },
  // {
  //   path: '/',
  //   name: 'Bottomtabbar',
  //   meta: { keepAlive: true },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/bottomtabbar.vue')
  // },
  {
    path: '/videolist',
    name: 'Videolist',
    meta: { tabbarShow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/videolist.vue')
  },
  {
    path: '/poslist',
    name: 'Poslist',
    meta: { tabbarShow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/poslist.vue')
  },
  {
    path: '/jobListDetail',
    name: 'JobListDetail',
    meta: { title: '', isleftarrow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/detail.vue')
  },
  {
    path: '/message',
    name: 'Message',
    meta: { tabbarShow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/message.vue')
  },
  {
    path: '/messages',
    name: 'Messagesocket',
    meta: { tabbarShow: true, navShow: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/messagesocket.vue')
  },
  {
    path: '/center',
    name: 'Center',
    meta: { title: '个人中心', tabbarShow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/center.vue')
  },
  {
    path: '/seek',
    name: '/Seek',
    meta: { title: '求职意向', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/seek.vue')
  },
  {
    path: '/addSeek',
    name: 'AddSeek',
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/addSeek.vue')
  },
  {
    path: '/job',
    name: 'Job',
    meta: { title: '期望职位', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/job.vue')
  },
  {
    path: '/branch',
    name: 'Branch',
    meta: { title: '选择行业', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/branch.vue')
  },
  {
    path: '/selectCity',
    name: 'SelectCity',
    meta: { title: '选择城市', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/selectCity.vue')
  },
  {
    path: '/myInfo',
    name: 'MyInfo',
    meta: { title: '个人信息', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/myInfo.vue')
  },
  {
    path: '/myInfoEdit',
    name: 'MyInfoEdit',
    meta: { title: '编辑个人信息', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/myInfoEdit.vue')
  },
  {
    path: '/onlineResume',
    name: 'OnlineResume',
    meta: { title: '在线简历', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/onlineResume.vue')
  },
  {
    path: '/vipResume',
    name: 'VipResume',
    meta: { title: 'VIP简历', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/vipResume/index.vue')
  },
  {
    path: '/vipResumeDetail',
    name: 'VipResumeDetail',
    meta: { title: 'VIP简历', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/vipResume/detail.vue')
  },
  {
    path: '/workExperience',
    name: 'WorkExperience',
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/workExperience.vue')
  },
  {
    path: '/educationalExperience',
    name: 'EducationalExperience',
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/educationalExperience.vue')
  },
  {
    path: '/projectExperience',
    name: 'ProjectExperience',
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/projectExperience.vue')
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/certificate.vue')
  },
  {
    path: '/evaluation',
    name: 'Evaluation',
    meta: { title: '自我评价', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/evaluation.vue')
  },
  {
    path: '/skill',
    name: 'Skill',
    meta: { title: '选择拥有的技能', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../components/skill.vue')
  },
  {
    path: '/attachedResume',
    name: 'AttachedResume',
    meta: { title: '附件简历', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/onlineResume/attachedResume.vue')
  },
  {
    path: '/videoResume',
    name: 'VideoResume',
    meta: { title: '视频简历', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/videoResume/videoResume.vue')
  },
  {
    path: '/addVideoResume',
    name: 'AddVideoResume',
    meta: { title: '视频简历', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/videoResume/addVideoResume.vue')
  },
  {
    path: '/videoResumeDetail',
    name: 'VideoResumeDetail',
    // meta: { title: '视频简历', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/videoResume/videoResumeDetail.vue')
  },
  {
    path: '/videoResumes',
    name: 'VideoResumes',
    // meta: { title: '视频简历', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/videoResume/video.vue')
  },
  {
    path: '/collectList',
    name: 'CollectList',
    // meta: { title: '我的收藏', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/collectList.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: { title: '帮助反馈', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/help.vue')
  },
  {
    path: '/violationList',
    name: 'ViolationList',
    meta: { title: '违规公告', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/violationList.vue')
  },
  {
    path: '/violationDetail',
    name: 'ViolationDetail',
    meta: { title: '违规公告', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/violationDetail.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: { title: '设置', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/setting/setting.vue')
  },
  {
    path: '/shieldCompany',
    name: 'ShieldCompany',
    meta: { title: '屏蔽公司', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/setting/shieldCompany.vue')
  },
  {
    path: '/addShieldCompany',
    name: 'AddShieldCompany',
    meta: { title: '添加屏蔽公司', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/setting/addShieldCompany.vue')
  },
  {
    path: '/selectCity',
    name: 'SelectCity',
    meta: { title: '选择城市', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mycenter/seek/selectCity.vue')
  },
  {
    path: '/index',
    name: 'Index',
    meta: { tabbarShow: true, navShow: false },
    component: () => import(/* webpackChunkName: "about" */ '../views/sns/index.vue')
  },
  {
    path: '/snsSearch',
    name: 'SnsSearch',
    meta: { title: '搜索', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/sns/search.vue')
  },
  {
    path: '/jobSearch',
    name: 'JobSearch',
    meta: { title: '搜索', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/search.vue')
  },
  {
    path: '/snsVideo',
    name: 'SnsVideo',
    meta: { keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/sns/video.vue')
  },
  {
    path: '/companyInfo',
    name: 'CompanyInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/sns/companyinfo.vue')
  },
  {
    path: '/jobList',
    name: 'JobList',
    component: () => import(/* webpackChunkName: "about" */ '../views/job/list.vue')
  },

  {
    path: '/jobDetail/:id/',
    name: 'JobDetail',
    // meta: { title: '招聘详情', isleftarrow: false, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/jobdetail.vue')
  },
  {
    path: '/seekDetail/:id/',
    name: 'SeekDetail',
    meta: { title: '求职详情', isleftarrow: false, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/seekdetail.vue')
  },
  {
    path: '/filter',
    name: 'Filter',
    meta: { title: '筛选', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../components/filter.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    // meta: { title: '在线沟通', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/chat.vue')
  },
  {
    path: '/chats',
    name: 'Chats',
    // meta: { title: '在线沟通', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/chatsocket.vue')
  },
  {
    path: '/report',
    name: 'Report',
    meta: { title: '在线沟通', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/report.vue')
  },

  {
    path: '/reports',
    name: 'Reports',
    meta: { title: '举报', isleftarrow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/job/reports.vue')
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
