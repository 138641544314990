<template>
  <!-- <div>
      <div class="start_1 animated fadeInLeft"><van-image width="235" height="47" :src="require('../assets/imgs/start-1.png')" /></div>
      <div class="start_2 animated fadeInRight"><van-image width="200" height="44" :src="require('../assets/imgs/start-2.png')" /></div>
      <div class="start_3 animated enlarge"><van-image width="60" height="60" :src="require('../assets/imgs/logo_big.png')" /></div>
  </div> -->
  <div>
    <img src="../assets/img/start.gif" />
  </div>
</template>
<script>
export default {
  name: 'Start',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timer: 2
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setTimer()
    })
  },
  methods: {
    setTimer () {
      var that = this
      const timerFunction = setInterval(function () {
        that.timer--
        if (that.timer <= 0) {
          clearInterval(timerFunction)
          that.toClose()
        };
      }, 1000)
    },
    toClose () {
      this.$emit('closeStart', true)
    }
  }
}
</script>
<style>
img{
    width: 100%;
    height: 100vh;
}
.start_1{
  margin-top: 72px;
  text-align: center;
}
.start_2{
  margin-top: 10px;
  text-align: center;
}
.start_3{
  margin-top: 250px;
  text-align: center;
}
/*动画-start*/
.animated {
    animation-duration: 1.5s !important;
    -webkit-animation-duration: 1.5s !important;
    animation-fill-mode: both !important;
    -webkit-animation-fill-mode: both !important;
}
.animated02 {
    animation-duration: 1s !important;
    -webkit-animation-duration: 1s !important;
    animation-fill-mode: both !important;
    -webkit-animation-fill-mode: both !important;
}
/*逐渐显示*/
.toShow {
    position: absolute;
    animation: toShow 1.5s 0.5s;
    animation: toShow 1.5s 0.5s;
    animation-fill-mode: both !important;
    -webkit-animation-fill-mode: both !important;
}
/* 放大效果*/
.enlarge {
    animation-name: enlarge;
    -webkit-animation: enlarge;
}
 /*从上到下进入*/
.fadeInDown {
    animation-name: fadeInDown;
    -webkit-animation: fadeInDown;
}
/*从下到上进入*/
.fadeInUpLeft {
    animation-name: fadeInUpLeft;
    -webkit-animation: fadeInUpLeft;
}
/*从右到左进入*/
.fadeInRight {
    animation-name: fadeInRight;
    -webkit-animation: fadeInRight;
}
/*从左到右进入*/
.fadeInLeft {
    animation-name: fadeInLeft;
    -webkit-animation: fadeInLeft;
}
/*中心旋转*/
.coreRotate {
    animation-name: coreRotate;
    -webkit-animation-name: coreRotate;
}

@keyframes toShow {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@-webkit-keyframes toShow {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes enlarge
{
    from {
        opacity: 0;
        -ms-transform: scale(0.1,0.1); /* IE 9 */
        -webkit-transform: scale(0.1,0.1); /* Safari */
        transform: scale(0.1,0.1); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform:scale(1,1);
        transform:scale(1,1);
    }
}

@-webkit-keyframes enlarge /* Safari 与 Chrome */
{
    from {
        opacity:0;
        -ms-transform: scale(0.1,0.1); /* IE 9 */
        -webkit-transform: scale(0.1,0.1); /* Safari */
        transform: scale(0.1,0.1); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform:scale(1,1);
        transform:scale(1,1);
    }
}

/*从上到下*/
@keyframes fadeInDown
{
    from {
        opacity: 0;
        -webkit-transform: translate(0,-1000px); /* Safari */
        transform: translate(0,-1000px); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform: translate(0,10px); /* Safari */
        transform: translate(0,10px); /* 标准语法 */
    }
}

@-webkit-keyframes fadeInDown /* Safari 与 Chrome */
{
    from {
        opacity:0;
        -webkit-transform: translate(0,-1000px); /* Safari */
        transform: translate(0,-1000px); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform: translate(0,10px); /* Safari */
        transform: translate(0,10px); /* 标准语法 */
    }
}
/*从左下到右上*/
@keyframes fadeInUpLeft
{
    from {
        opacity: 0;
        -webkit-transform: translate(-1000px,1000px); /* Safari */
        transform: translate(-1000px,1000px); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform: translate(0,10px); /* Safari */
        transform: translate(0,10px); /* 标准语法 */
    }
}

@-webkit-keyframes fadeInUpLeft /* Safari 与 Chrome */
{
    from {
        opacity:0;
        -webkit-transform: translate(-1000px,1000px); /* Safari */
        transform: translate(-1000px,1000px); /* 标准语法 */
    }
    to {
        opacity:1;
        -webkit-transform: translate(0,10px); /* Safari */
        transform: translate(0,10px); /* 标准语法 */
    }
}
/*从右到左进入*/
@keyframes fadeInRight
{
    from {
        opacity: 0;
        -webkit-transform: translate(1000px,0);
        transform: translate(1000px,0);
    }
    to {
        opacity:1;
        -webkit-transform: translate(10px,0);
        transform: translate(10px,0);
    }
}

@-webkit-keyframes fadeInRight
{
    from {
        opacity:0;
       -webkit-transform: translate(1000px,0);
        transform: translate(1000px,0);
    }
    to {
        opacity:1;
        -webkit-transform: translate(10px,0);
        transform: translate(10px,0);
    }
}
/*从左到右进入*/
@keyframes fadeInLeft
{
    from {
        opacity: 0;
        -webkit-transform: translate(-1000px,0);
        transform: translate(-1000px,0);
    }
    to {
        opacity:1;
        -webkit-transform: translate(10px,0);
        transform: translate(10px,0);
    }
}

@-webkit-keyframes fadeInLeft
{
    from {
        opacity:0;
       -webkit-transform: translate(-1000px,0);
        transform: translate(-1000px,0);
    }
    to {
        opacity:1;
        -webkit-transform: translate(10px,0);
        transform: translate(10px,0);
    }
}

/*绕中心旋转*/
@keyframes coreRotate {
    5% {
        transform:rotate(5deg);
        -ms-transform:rotate(5deg); /* IE 9 */
        -webkit-transform:rotate(5deg); /* Safari and Chrome */
    }
    30% {
        transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -webkit-transform:rotate(-5deg);
    }
    60% {
        transform:rotate(5deg);
        -ms-transform:rotate(5deg);
        -webkit-transform:rotate(5deg);
    }
    90% {
        transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -webkit-transform:rotate(-5deg);
    }
}
@-webkit-keyframes coreRotate {
    5% {
        transform:rotate(5deg);
        -ms-transform:rotate(5deg); /* IE 9 */
        -webkit-transform:rotate(5deg); /* Safari and Chrome */
    }
    30% {
        transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -webkit-transform:rotate(-5deg);
    }
    60% {
        transform:rotate(5deg);
        -ms-transform:rotate(5deg);
        -webkit-transform:rotate(5deg);
    }
    90% {
        transform:rotate(-5deg);
        -ms-transform:rotate(-5deg);
        -webkit-transform:rotate(-5deg);
    }
}
/*动画-end*/
</style>
