import url from '@/URL'
import store from '@/store'
export function socketFun () {
  console.log('socket')
  var timer
  var senderId = localStorage.getItem('userId')
  var socket
  if (window.WebSocket && senderId) {
    // if (store.getters.getSocket) {
    //   socket = store.getters.getSocket
    //   console.log('socket==== store.getters.getSocket' + socket)
    // } else {
    socket = new WebSocket(url.socketUrl)
    console.log('socket====socket' + socket)
    // }
    socket.onopen = function () {
      console.log('连接成功')
      store.commit('setSocket', socket)
      onConnect()
      // alert('socket.js=====连接成功')
    }
    socket.onclose = function () {
      clearInterval(timer)
      socketFun()
      // alert(type)
      // alert('socket.js=====连接关闭')
      console.log('连接关闭')
    }
    socket.onerror = function () {
      clearInterval(timer)
      socket.close()
      // alert('socket.js=====连接出错')
      console.log('连接出错')
    }
    socket.onmessage = function (e) {
      // alert(e.data)
      console.log('eeee========', e)
      const data = JSON.parse(e.data)
      store.commit('setMessages', data)
      timer = setInterval(function () {
        keepalive()
      }, 10000)
    }
  } else {
    console.log('浏览器不支持websocket协议')
  }
  function keepalive () {
    var chatMsg = {}
    chatMsg.type = 4
    chatMsg.senderId = senderId

    socket.send(JSON.stringify(chatMsg))
  }
  function onConnect () {
    var chatMsg = {}
    chatMsg.senderId = senderId
    chatMsg.msg = ''
    chatMsg.type = 1
    // alert(localStorage.getItem('userId'))
    console.log('onConnect', chatMsg)
    socket.send(JSON.stringify(chatMsg))
  }
  return socket
}
