<template>
    <van-tabbar v-model="actives" :fixed="true">
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
         replace
        :to="item.name"
      >
        <span :class="currIndex == index ? actives : ''">{{ item.title }}</span>
        <template v-slot:icon="props">
          <img :src="props.active ? item.active : item.normal" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
// import store from '@/store'
// import { socketFun } from '@/utils/socket'
// import WebSockets from '@/utils/websocket'
// import store from '@/store'

export default {
  name: 'Bottomtabbar',
  computed: {
    getClosed () {
      return this.$store.state.closed
    }
  },
  watch: {
    $route (to) {
      this.activeTab(to.path)
    }

  },
  data () {
    return {
      closed: false,
      currIndex: 0,
      actives: 0,
      tabbars: [
        // {
        //   name: 'videolist',
        //   title: '活儿',
        //   normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/video.png',
        //   active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/video-active.png'
        // },
        {
          name: 'poslist',
          title: '招聘',
          normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/pos.png',
          active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/pos-active.png'
        },
        {
          name: 'messages',
          title: '消息列表',
          normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/msg.png',
          active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/msg-active.png'
        },
        {
          name: 'center',
          title: '我的',
          normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/self-center.png',
          active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/image/self-center-active.png'
        }
      ]
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  mounted () {
    // WebSockets.initWebSocket()
    // this.sendConnect()
  },
  methods: {
    // sendConnect () {
    //   const myUserId = localStorage.getItem('userId')
    //   var _this = this
    //   var chatMsg = {}
    //   chatMsg.type = 1
    //   chatMsg.senderId = myUserId
    //   WebSockets.sendData(chatMsg, (msg) => {
    //     if (msg != null) {
    //       const res = JSON.parse(msg.data)
    //       console.log('聊天消息', res)
    //       console.log('msg', res)
    //       if (res.code === 200) {
    //         _this.reload = false
    //         _this.sendHearBeat()
    //       } else if (res.code === 400) {
    //         // this.closeSocket()
    //       } else {
    //         store.commit('setMessages', res)
    //       }
    //     }
    //   })
    // },
    // sendHearBeat () {
    //   var _this = this
    //   const myUserId = localStorage.getItem('userId')
    //   _this.timer = setInterval(function () {
    //     var chatMsg = {}
    //     chatMsg.type = 4
    //     chatMsg.senderId = myUserId
    //     WebSockets.sendDataWithoutCallback(chatMsg)
    //     console.log('发送心跳中')
    //   }, 10000)
    // },
    // closeSocket () {
    //   clearInterval(this.timer)
    //   WebSockets.closewebsocket()
    // },
    activeTab (path) {
      var index = this.tabbars.map((item) => item.name).indexOf(path)
      if (index !== -1) {
        this.actives = index
      }
    }
  },
  created () {
    // this.tab(0, 'Videolist')
    const routerPath = this.$route.path
    if (routerPath === '/poslist') {
      this.actives = 0
    } else if (routerPath === '/messages') {
      this.actives = 1
    } else if (routerPath === '/center') {
      this.actives = 2
    } else {
      this.actives = 0
    }
    // if (this.$store.getters.getSocket) {
    //   this.socket = this.$store.getters.getSocket
    // } else {
    //   const socket = socketFun()
    //   this.socket = socket
    //   this.socket.onmessage = function (e) {
    //     // const data =
    //     store.commit('setMessages', JSON.parse(e.data))
    //     console.log(e.data)
    //     console.log('接受到了消息tabbar')
    //   }
    // }
  }
}
</script>

<style scoped>
.container {
  font-size: 14px;
  padding: 0;
  background: #f7f7f7;
  padding-top: 0px;
  /* min-height: 84vh; */
}
.van-tabbar-item--active {
  color: #0DD9A6;
}
img{
  width: 20px;
}

</style>
