import request from '../utils/request'
// 用户选择身份接口(选择个人用户时调用此接口)
export function changeIdentity (data) {
  return request({
    url: '/appreq/appUser/changeIdentity',
    method: 'POST',
    headers: {
      loading: false
    },
    data: data
  })
}
// 获取用户注册进度
export function getCurrentProgress (query) {
  return request({
    url: '/appreq/process/getCurrentProgress',
    method: 'GET',
    params: query
  })
}
//  更新用户注册进度
export function addProgress (data) {
  return request({
    url: '/appreq/process/addProgress',
    method: 'POST',
    headers: {
      loading: false
    },
    data: data
  })
}
// 求职者：1. 注册第一步
export function updatePersonInfo (data) {
  return request({
    url: '/appreq/appUser/updatePersonInfo',
    method: 'POST',
    data: data
  })
}
// 求职者：2. 注册第二步和第三步
export function updateUserResume (data) {
  return request({
    url: '/appreq/resume/updateUserResume',
    method: 'POST',
    data: data
  })
}
// 求职者：3. 注册第四步
export function addUserJobintention (data) {
  return request({
    url: '/appreq/jobintention/addUserJobintention',
    method: 'POST',
    data: data
  })
}
// 求职者：4. 注册第五步和第六步
export function updateUserJobintention (data) {
  return request({
    url: '/appreq/jobintention/updateUserJobintention',
    method: 'POST',
    headers: {
      loading: false
    },
    data: data
  })
}

// 获取用户求职意向
export function getUserJobintentionList (query) {
  return request({
    url: '/appreq/jobintention/getUserJobintention',
    method: 'GET',
    params: query
  })
}
// 添加用户求职意向(和注册第四步接口地址一致)
// export function addUserJobintention (data) {
//   return request({
//     url: '/appreq/jobintention/addUserJobintention',
//     method: 'POST',
//     data: data
//   })
// }
// 修改用户求职意向（在线）
export function updateUserJobintentionNew (data) {
  return request({
    url: '/appreq/jobintention/updateUserJobintentionNew',
    method: 'POST',
    data: data
  })
}
// 删除用户求职意向
export function delUserJobintention (id) {
  return request({
    url: '/appreq/jobintention/deleteUserJobintention/' + id,
    method: 'DELETE'
  })
}
// 获取第一等级的职位列表
export function getFirstClassCarea (query) {
  return request({
    url: '/appreq/carea/getFirstClassCarea',
    method: 'GET',
    params: query
  })
}
// 根据父类id获取所有子类的职位列表
export function getChildCarea (query) {
  return request({
    url: '/appreq/carea/getChildCarea',
    method: 'GET',
    params: query
  })
}
