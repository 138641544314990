<template>
  <div class="login" id="login">
    <div class="logo">
      <img alt="logo" src="../../assets/img/logo.png" />
    </div>
    <form>
      <div>
        <label>手机号登录/注册</label>
        <input
          v-model="form.username"
          placeholder="手机号登录/注册"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
      </div>
      <div style="position: relative;">
        <label>验证码</label>
        <input
          v-model="form.code"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        />
        <div class="code-btn" @click="getCode">{{codeText}}</div>
      </div>
      <div style="margin-top: 20px" @click="goLogin">
        <van-button type="primary" block round>登录</van-button>
      </div>
    </form>
    <div id="container"></div>
    <!-- <div class="nother">
      <div class="col6 front13">-第三方授权登录-</div>
      <div style="margin:20px auto 10px;">
        <van-image
          round
          width="80px"
          height="80px"
          fit="cover"
          :src="require('@/assets/imgs/wx.png')"
        />
        <div class="front13">微信登录</div>
      </div>
    </div> -->
    <div class="protocol">
      <van-checkbox v-model="checked" checked-color="#0DD9A6" icon-size="16px"></van-checkbox>
      <span class="front13">我已阅读并同意<a href="http://www.shuahuo.cn/portal/content/index.html?id=13&cid=9" target="_blank">《用户协议》</a>和<a href="http://www.shuahuo.cn/portal/content/index.html?id=12&cid=9" target="_blank">《隐私政策》</a></span>
    </div>
    <van-popup v-model:show="showStart" position="top" :style="{ height: '100%' }" custom-style="overflow: hidden" >
      <start @closeStart="closeStart"></start>
    </van-popup>
  </div>
</template>

<script>
import { getUserInfoById, getAddActive, getAddVisitNum } from '@/api/common'
import { getCode, login } from '@/api/login'
import { getCurrentProgress, changeIdentity, addProgress } from '@/api/baseInfo'
import { getToken, setToken } from '@/utils/auth'
// import { timLogin } from '@/utils/tim'
import start from '@/components/start'
import { socketFun } from '@/utils/socket'
// import TIM from 'tim-js-sdk'
export default {
  name: 'Login',
  components: {
    start
  },
  data () {
    return {
      userType: '',
      codeText: '获取验证码',
      form: {
        username: undefined,
        code: undefined
      },
      checked: false,
      tim: undefined,
      showStart: true,
      socket: undefined
    }
  },
  mounted () {
    this.getAddVisitNumData()
  },
  methods: {
    getToken () {
      const token = getToken()
      if (token) {
        this.$router.push({ path: '/videolist' })
      }
    },
    checkPhone (val) {
      let flag = true
      if (val) {
        if (!(/^1[3456789]\d{9}$/.test(val))) {
          flag = false
        }
        return flag
      }
    },

    getUserInfo (userId) {
      var promise = getUserInfoById(userId).then((res) => {
        return res
      })
      return promise
    },
    getCode () {
      var that = this
      var timer
      var checkPhone = that.checkPhone(that.form.username)
      if (that.codeText !== '获取验证码') {
        this.$toast({
          message: '验证码已发送，请注意查收',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      if (!this.form.username) {
        that.$toast({
          message: '请输入您的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      } else if (!checkPhone) {
        that.$toast({
          message: '请输入正确的的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      getCode({ phone: this.form.username }).then(response => {
        if (response.code === 200) {
          var ss = 59
          timer = setInterval(function () {
            ss--
            that.codeText = '验证码(' + ss + 's)'
            if (ss <= 0) {
              clearInterval(timer)
              that.codeText = '获取验证码'
            };
          }, 1000)
        } else {
          clearInterval(timer)
          that.setData({
            codeText: '获取验证码'
          })
        }
      })
    },
    goLogin () {
      var that = this
      var checkPhone = that.checkPhone(that.form.username)
      if (!that.form.username) {
        that.$toast({
          message: '请输入您的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      } else if (!checkPhone) {
        that.$toast({
          message: '请输入正确的的手机号码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      if (!that.form.code) {
        that.$toast({
          message: '请输入验证码',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      if (!that.checked) {
        that.$toast({
          message: '请先阅读并同意用户协议和隐私政策',
          icon: 'none',
          duration: 2000
        })
        return false
      };
      login({ phonenumber: that.form.username, code: that.form.code }, 'loading').then(response => {
        if (response.code === 200) {
          const userId = response.data.user.id
          const type = response.data.user.type
          if (type === '1') {
            localStorage.setItem('userId', userId)
            setToken(response.data.token)
            // timLogin(userId.toString())
            getCurrentProgress().then(progress => {
              if (progress.code === 410) {
                changeIdentity({ type: 1 }).then(identity => { })
                addProgress({ progress: 0 }).then(add => {
                  if (add.code === 200) {
                    that.$router.push({ path: '/baseInfo' })
                  }
                })
              } else if (progress.code === 200) {
                if (progress.data[0].progress === 0) {
                  that.$router.push({ path: '/baseInfo' })
                } else {
                  that.$router.push({ path: '/poslist' })
                }
                // if (progress.data[0].progress === 0) {
                //   that.$router.push({ path: '/baseInfo', query: { progress: progress.data[0].progress } })
                // } else if (progress.data[0].progress === 1) {
                //   that.$router.push({ path: '/jobintention', query: { progress: progress.data[0].progress } })
                // } else if (progress.data[0].progress === 2) {
                //   that.$router.push({ path: '/baseInfo', query: { progress: progress.data[0].progress } })
                // } else if (progress.data[0].progress === 3) {
                //   that.$router.push({ path: '/baseInfo', query: { progress: progress.data[0].progress } })
                // } else if (progress.data[0].progress === 4) {
                //   that.$router.push({ path: '/jobintention', query: { progress: progress.data[0].progress } })
                // } else if (progress.data[0].progress === 5) {
                //   that.$router.push({ path: '/baseInfoPay', query: { progress: progress.data[0].progress } })
                // } else {
                // that.$router.push({ path: '/poslist' })
                // }
              }
            })
            that.getAddActiveData()
            const socket = socketFun()
            this.socket = socket
          } else {
            this.$toast({
              message: '企业类型用户请登录app',
              icon: 'none',
              duration: 2000
            })
            return false
          }
        }
      })
    },
    // timLogin (userId) {
    //   var that = this
    //   const options = {
    //     SDKAppID: 1400597142, // 接入时需要将0替换为您的云通信应用的 SDKAppID，类型为 Number
    //     SECRETKEY: '36b00ee3af2c42a18f97a13c1d0da1f2f07423a7313656317fd06bbfe7ec6a4d'
    //   }
    //   var generator = new window.LibGenerateTestUserSig(
    //     options.SDKAppID,
    //     options.SECRETKEY,
    //     5000
    //   )
    //   var userSig = generator.genTestUserSig(userId)
    //   that.tim = TIM.create(options) // SDK 实例通常用 tim 表示
    //   const promise = that.tim.login({ userID: userId, userSig: userSig })
    //   promise.then(function (imResponse) {
    //     console.log(imResponse.data) // 登录成功
    //     that.$store.commit('setTim', that.tim)
    //     console.log(that.tim)
    //     if (imResponse.data.repeatLogin === true) {
    //       // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
    //       console.log(imResponse.data.errorInfo)
    //     }
    //   }).catch(function (imError) {
    //     console.warn('login error:', imError) // 登录失败的相关信息
    //   })
    // },
    goTo (str) {
      this.$router.push({ path: '/protocol', query: { type: str } })
    },
    closeStart (val) {
      const token = getToken()
      var that = this
      if (token) {
        const socket = socketFun()
        that.socket = socket
        getCurrentProgress().then(progress => {
          if (progress.code === 200) {
            if (progress.data[0].progress === 0) {
              that.$router.push({ path: '/baseInfo' })
            } else {
              that.$router.push({ path: '/poslist' })
            }
            // else if (progress.data[0].progress === 1) {
            //   that.$router.push({ path: '/jobintention', query: { progress: progress.data[0].progress } })
            // } else if (progress.data[0].progress === 2) {
            //   that.$router.push({ path: '/baseInfo', query: { progress: progress.data[0].progress } })
            // } else if (progress.data[0].progress === 3) {
            //   that.$router.push({ path: '/baseInfo', query: { progress: progress.data[0].progress } })
            // } else if (progress.data[0].progress === 4) {
            //   that.$router.push({ path: '/jobintention', query: { progress: progress.data[0].progress } })
            // } else if (progress.data[0].progress === 5) {
            //   that.$router.push({ path: '/baseInfoPay', query: { progress: progress.data[0].progress } })
            // } else {
            // that.$router.push({ path: '/poslist' })
            // }
          }
        })
        that.getAddActiveData()
      } else {
        this.showStart = false
      }
      // this.getToken()
    },
    getAddActiveData () {
      getAddActive({ type: '2' }).then(progress => {})
    },
    getAddVisitNumData () {
      getAddVisitNum().then(progress => {})
    }
  }
}
</script>
<style>
#login .van-popup{
  overflow: hidden;
}
</style>
<style scoped>
/* .div-start{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url('../../assets/img/start.gif') 0 0 no-repeat;
  background-size: 100% 100%;
} */
.login{
  min-height: calc(100vh - 66px);
  background: #fff;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.logo img {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  /* padding-top: 30px; */
}
form {
  font-size: 14px;
  line-height: 44px;
  padding: 20px 40px;
}
form label {
  display: block;
}
form input {
  display: block;
  padding-left: 15px;
  width: calc(100% - 15px);
  border-radius: 5px;
  background-color: rgba(247, 247, 247, 100);
  border: none;
}
.code-btn{
  width: 90px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #0DD9A6;
  border-left: solid 1px #0DD9A6;
  position: absolute;
  top: 58px;
  right: 0;
}
.nother{
  text-align: center;
}
.protocol{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.protocol span{
  padding-left: 6px;
}
a{
   color: #0DD9A6;
   text-decoration: none;
}
.van-popup{
  overflow: hidden;
}
</style>
