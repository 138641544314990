<template>
  <div id="NavBar">
    <van-nav-bar :title="title" :border="false" :left-arrow="isleftarrow" @click-left="onClickLeft" />
  </div>
</template>
<script>
export default {
  // title：用来显示导航栏的title，isleftarrow用来显示导航栏的左侧返回箭头
  props: ['title', 'isleftarrow'],
  methods: {
    onClickLeft () {
      // 点击回退的时候当做地址回退
      this.$router.go(-1)
    }
  }
}
</script>
