<template>
  <div class="home" @click="handleClick">
    <van-nav-bar title="标题" />
    <img alt="Vue logo" src="../assets/imgs/logo.png" />
    <Header :message="2222"></Header>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/views/Header'
import { login } from '@/api/login'
export default {
  name: 'Home',
  components: {

    Header
  },
  methods: {
    handleClick () {
      var data = { username: '111' }
      login(data).then((response) => {
        console.log(response.data)
      })
      alert(111)
    }
  }
}
</script>
