import request from '../utils/request'
// 获取首页(职位列表)轮播图
export function getImgList (query) {
  return request({
    url: '/appreq/banner/getList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 分享信息获取签名
export function getSignature (data) {
  return request({
    url: '/appreq/wxShare/getSignature',
    method: 'POST',
    headers: {
      isToken: false
    },
    data: data
  })
}
// 添加活跃用户记录
export function getAddActive (data) {
  return request({
    url: '/appreq/activeUser/addActive',
    method: 'POST',
    data: data
  })
}
// 添加访问用户记录
export function getAddVisitNum (data) {
  return request({
    url: '/appreq/visit/addVisitNum',
    method: 'POST',
    headers: {
      isToken: false
    },
    data: data
  })
}
// 获取所有行业信息
export function getAllBranch (query) {
  return request({
    url: '/appreq/branch/getAllBranch',
    method: 'GET',
    params: query
  })
}
// 获取企业规模
export function getCompanyScaleList (query) {
  return request({
    url: '/appreq/companyScale/getCompanyScaleList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 薪资范畴列表接口
export function getSalaryList (query) {
  return request({
    url: '/appreq/salary/getSalaryList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 薪资范畴列表接口 (显示nK)
export function getSalaryListNew (query) {
  return request({
    url: '/appreq/salary/getSalaryListNew',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 获取所有第一等级行业
export function getFirstClassBranch (query) {
  return request({
    url: '/appreq/branch/getFirstClassBranch',
    method: 'GET',
    params: query
  })
}
// 获取所有第一等级下对应ID的所有行业
export function getChildBranch (query) {
  return request({
    url: '/appreq/branch/getChildBranch',
    method: 'GET',
    params: query
  })
}

// 根据字典类型查询字典数据信息
export function getDicts (dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 获取教育经历list
export function getEducationList () {
  return request({
    url: '/appreq/education/getEducationList',
    method: 'get',
    headers: {
      loading: false
    }
  })
}

// 增加视频播放量
export function addPlayNum (data) {
  return request({
    url: '/appreq/userVideoResume/addPlayNum',
    method: 'POST',
    headers: {
      loading: false
    },
    data: data
  })
}
// 根据视频id查询评论列表
export function getCommentList (query, data) {
  return request({
    url: '/appreq/forumComment/getCommentList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 根据视频id添加视频评论
export function addComment (data) {
  return request({
    url: '/appreq/forumComment/addComment',
    method: 'POST',
    data: data
  })
}
// 根据视频id删除视频评论
export function delComment (id) {
  return request({
    url: '/appreq/forumComment/delComment/' + id,
    method: 'DELETE'
  })
}

// 聊天会话记录
export function getConversitionList (query, data) {
  return request({
    url: '/appreq/chatMsg/getConversitionList',
    method: 'GET',
    params: query,
    data: data,
    headers: {
      loading: false
    }
  })
}
// 聊天会话记录（websocket）
export function getConversitionListNew (query, data) {
  return request({
    url: '/appreq/chatMsg/getConversitionListNew',
    method: 'GET',
    params: query,
    data: data,
    headers: {
      loading: false
    }
  })
}
// 聊天记录（websocket）
export function getMsgContentList (query, data) {
  return request({
    url: '/appreq/chatMsgContent/getMsgContentList',
    method: 'POST',
    params: query,
    data: data,
    headers: {
      loading: false
    }
  })
}
// 聊天记录 更新消息状态为已读（websocket）
export function msgContent (data) {
  return request({
    url: '/appreq/chatMsgContent/msgContent',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}

export function checkConversition (data) {
  return request({
    url: '/appreq/chatMsg/selectConversition',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}
// 工作经验
export function getExperienceList () {
  return request({
    url: '/appreq/experience/getExperienceList',
    method: 'GET',
    headers: {
      loading: false
    }
  })
}
// 根据userId获取用户基本信息
export function getUserInfoById (id) {
  return request({
    url: '/appreq/appUser/getUserInfoById/' + id,
    method: 'GET',
    headers: {
      loading: false
    }
  })
}
// 对我感兴趣列表
export function getIntrestInList (query) {
  return request({
    url: '/appreq/intrestin/getIntrestInList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 看过我列表
export function getLookUpList (query) {
  return request({
    url: '/appreq/lookup/getLookUpList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 新职位列表
export function getNewList (query) {
  return request({
    url: '/appreq/new/getNewList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 获取搜索记录（活儿列表pageType：2，职位列表pageType：1）
export function getSearchRecordList (query) {
  return request({
    url: '/appreq/searchrecord/getSeachRecordList',
    method: 'GET',
    params: query,
    headers: {
      loading: false
    }
  })
}
// 获取推荐职位（活儿列表）
// export function getApaReCommendCarea () {
//   return request({
//     url: '/apapreq/carea/getReCommendCarea',
//     method: 'GET',
//     headers: {
//       loading: false
//     }
//   })
// }
// 推荐职位
export function getReCommendCarea () {
  return request({
    url: '/appreq/carea/getReCommendCarea',
    method: 'GET',
    headers: {
      loading: false
    }
  })
}

// 清空搜索记录
export function delSearchRecord () {
  return request({
    url: '/appreq/searchrecord/delSearchRecordByUserId',
    method: 'DELETE'
  })
}

// 添加搜索记录
export function addSearch (data) {
  return request({
    url: '/appreq/searchrecord/addSeachRecord',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}

// 根据内容搜索
export function search (data) {
  return request({
    url: '/appreq/searchrecord/search',
    method: 'POST',
    data: data
  })
}
//
export function deleteConversition (data) {
  return request({
    url: '/appreq/chatMsg/deleteConversition',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}

// 第一次聊天需要发职位信息
export function addChatMsg (data) {
  return request({
    url: '/appreq/chatMsgContent/addChatMsg',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}
// 交换微信号（没有绑定过微信号发）
export function updateWxNumber (data) {
  return request({
    url: '/appreq/appUser/update',
    method: 'POST',
    data: data,
    headers: {
      loading: false
    }
  })
}
